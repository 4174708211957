import React from "react"
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CompServices from '../components/comp-services'
import CompStudies from '../components/comp-studies'
import CompContact from '../components/comp-contact'


const Home = () => {
  return (
    <Layout>

      <SEO />

      {/* Intro */}
      <section className="home__intro-section">
        <h1 className="home__intro-title">
          <span className="home__intro-title-sub">Small Business</span>
          <span className="home__intro-title-main">Marketing Agency</span>
        </h1>
        <h2 className="home__intro-heading">Put Your Business On The Map</h2>
        
        <p className="home__intro-p">We help businesses leverage technology to fuel sustainable long-term growth for the best return on marketing investment possible.</p>

        <br />

        <p className="home__intro-p">Make it easy for customers to find you by establishing an online presence everywhere your customer are.</p>

      </section>



      <CompServices />


      
      {/* Local Talent Showcase */}
      <div className="home__locals">
      
        <h2 className="home__locals-heading">Local Influencer Spotlight</h2>

        <div className="home__locals-flex">
          <Link to="/local-talent/juan-velazquez-murals" className=" home__locals-card">
            <h3>Juan Velazquez</h3>
            <p><em>Artist</em></p>
            <p>Canvas &amp; Murals</p>
            <br />
            <p>See more &rarr;</p>
          </Link>

          <Link to="/local-talent/joe-fort-worth-rapper" className="home__locals-card">
            <h3>J/O/E</h3>
            <p><em>Musician</em></p>
            <p>Hip Hop &amp; Rap</p>
            <br />
            <p>See more &rarr;</p>
          </Link>
        </div>

      </div>

      <CompStudies />

      <CompContact />

    </Layout>
  )
}

export default Home
