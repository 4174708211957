import React from 'react'
import { Link } from 'gatsby'


const CompContact = () => {

  return (

  <section className="compcont-section">

    <h2 className="compcont-heading">
      <Link to="/contact">Contact Us</Link>
    </h2>

    <p>Phone: 817-502-3879</p>
    <p>Email: info@dfw-studio.com</p>
    <br />
    <br />

    <div className="compcont-container">
    <form className="compcont-form" name="contact" data-netlify="true">

      <label htmlFor="biz-name" className="compcont-label">Business Name:</label>
      <input type="text" id="biz-name" name="biz-name" className="compcont-field" required/>

      <label htmlFor="name" className="compcont-label">Your Name:</label>
      <input type="text" id="name" name="name" className="compcont-field" required/>
      
      <label htmlFor="email" className="compcont-label">Email:</label>
      <input type="email" id="email" name="email" className="compcont-field" required/>
      
      <label htmlFor="phone" className="compcont-label">Phone Number:<small> (Ex: 123-456-7890)</small></label>
      <input type="tel" id="phone" name="phone" className="compcont-field" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required/>
      

      <input className="compcont-button" id="submit" type="submit" value="Submit" />
      
    </form>
    </div>
  </section>

  )
}

export default CompContact