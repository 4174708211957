import React from 'react'
import { Link } from 'gatsby'

const CompServices = () => {
  return (

  <section className="compserv-section">

    <h2 className="compserv-heading">
    <Link to="/services">Services</Link>
    </h2>

    <div className="compserv-flex">
      <Link to="/services/small-business-marketing" className="compserv-card">
        <div className="compserv-info-side">
          <h3>Small Business Marketing</h3>
          <p><em>Starting at $149/m</em></p>
          <br />
          <p>A comprehensive local marketing package that will cover all the digital bases your business needs for customers to find you easily.</p>
          <br />
          <p>See more &rarr;</p>
        </div>

      </Link>

      <Link to="/services/search-engine-optimization" className="compserv-card">
        <h3>Search Engine Optimization</h3>
        <p><em>Starting at $499/m</em></p>
        <br />
        <p>If you already have a website and are happy with it but aren't getting any traffic we can help get your site to the first page of search results.</p>
        <br />
        <p>See more &rarr;</p>
      </Link>

      <Link to="/services/custom-web-design" className="compserv-card">
        <h3>Custom Web Design</h3>
        <p><em>Starting at $29/m</em></p>
        <br />
        <p>We will design, build, host and maintain a modern website running on the latest technology. Built from scratch to fit your needs.</p>
        <br />
        <p>See more &rarr;</p>
      </Link>
    </div>

  </section>
  )
}

export default CompServices